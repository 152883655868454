<template>
  <div class="info_warp">
    <div class="wrap">
      <div class="head_img">
        <div class="title">你的高考目标</div>
        <div class="text">我们好为你智能分析各考点的达标情况</div>
      </div>

      <el-form :model="form"
               label-width="220rem">
        <el-row>

          <el-col :span="24"
                  v-if="subjectList && subjectList.some(item => item.subject_id ==7)">
            <el-form-item label="语文：">
              <el-input-number v-model="subjectListData[0].score"
                               @change="handleChange(subjectListData[0])"
                               label="描述文字"></el-input-number>
            </el-form-item>
          </el-col>

          <el-col :span="24"
                  v-if="subjectList && subjectList.some(item => item.subject_id ==12)">
            <el-form-item label="数学：">
              <el-input-number v-model="subjectListData[1].score"
                               @change="handleChange(subjectListData[1])"
                               label="描述文字"></el-input-number>
            </el-form-item>
          </el-col>

          <el-col :span="24"
                  v-if="subjectList && subjectList.some(item => item.subject_id ==11)">
            <el-form-item label="英语：">
              <el-input-number v-model="subjectListData[2].score"
                               @change="handleChange(subjectListData[2])"
                               label="描述文字"></el-input-number>

            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="subjectList && subjectList.some(item => item.subject_id == 4)">
            <el-form-item label="物理：">
              <el-input-number v-model="subjectListData[3].score"
                               @change="handleChange(subjectListData[3])"
                               label="描述文字"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="subjectList && subjectList.some(item => item.subject_id == 9)">
            <el-form-item label="化学：">
              <el-input-number v-model="subjectListData[4].score"
                               @change="handleChange(subjectListData[4])"
                               label="描述文字"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="subjectList && subjectList.some(item => item.subject_id == 10)">
            <el-form-item label="生物学：">
              <el-input-number v-model="subjectListData[5].score"
                               @change="handleChange(subjectListData[5])"
                               label="描述文字"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="subjectList && subjectList.some(item => item.subject_id == 6)">
            <el-form-item label="历史：">
              <el-input-number v-model="subjectListData[6].score"
                               @change="handleChange(subjectListData[6])"
                               label="描述文字"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="subjectList && subjectList.some(item => item.subject_id ==5)">
            <el-form-item label="思想政治：">
              <el-input-number v-model="subjectListData[7].score"
                               @change="handleChange(subjectListData[7])"
                               label="描述文字"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="subjectList && subjectList.some(item => item.subject_id == 3)">
            <el-form-item label="地理：">
              <el-input-number v-model="subjectListData[8].score"
                               @change="handleChange(subjectListData[8])"
                               label="描述文字"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  :offset="4">
            <div class="btn"
                 @click="save">
              确 认
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>



<script>

import { mapMutations, mapState } from 'vuex'

export default {
  data () {
    return {
      form: {

      },
      isValid: true,
      subjectList: [],
      subjectListData: [

        {
          "subject_id": 7,
          "subject_name": "语文",
          'score': 130,
        },
        {
          "subject_id": 12,
          "subject_name": "数学",
          'score': 130,
        },
        {
          "subject_id": 11,
          "subject_name": "英语",
          'score': 130,
        },
        {
          "subject_id": 4,
          "subject_name": "物理",
          'score': 90,
        },
        {
          "subject_id": 9,
          "subject_name": "化学",
          'score': 90,
        },
        {
          "subject_id": 10,
          "subject_name": "生物学",
          'score': 90,
        },
        {
          "subject_id": 6,
          "subject_name": "历史",
          'score': 90,
        },
        {
          "subject_id": 5,
          "subject_name": "思想政治",
          'score': 90,
        },
        {
          "subject_id": 3,
          "subject_name": "地理",
          'score': 90,
        },
      ],
      gradeList: []
    }
  },
  created () {
    this.subjectList = JSON.parse(window.localStorage.getItem('subjectListData'))
    this.subjectListData.map(item => {
      this.subjectList.map(item2 => {
        if ((item2.subject_id == item.subject_id) && item.score !== 0) {
          item.score = item2.score
        }
      })
    })



  },
  mounted () {
  },
  methods: {
    InputDeviceInfo (val) {
      console.log('input', val)
    },
    handleChange (val) {
      let temp = val;
      this.isValid = true; // 设置为有效

      if (val.subject_id == 7 || val.subject_id == 12 || val.subject_id == 11) {
        if (temp.score <= 37) {
          this.$message.error(`${this.subjectListData.find(item => item.subject_id == val.subject_id).subject_name}目标分数最底：37分`);
          // setTimeout(() => {
          //   val.score = 37
          // }, 100)
          this.isValid = false;  // 设置为无效
        } else if (temp.score >= 150) {
          this.$message.error(`${this.subjectListData.find(item => item.subject_id == val.subject_id).subject_name}目标分数最高：150分`);
          // setTimeout(() => {
          //   val.score = 150
          // }, 100)
          this.isValid = false;  // 设置为无效
        }
      } else {

        if (temp.score <= 25) {
          this.$message.error(`${this.subjectListData.find(item => item.subject_id == val.subject_id).subject_name}目标分数最底：25分`);
          // setTimeout(() => {
          //   val.score = 25
          // }, 100)
          this.isValid = false;  // 设置为无效
        } else if (temp.score >= 100) {

          this.$message.error(`${this.subjectListData.find(item => item.subject_id == val.subject_id).subject_name}目标分数最高：100分`);

          // setTimeout(() => {
          //   val.score = 100
          // }, 100)
          this.isValid = false;  // 设置为无效

        }
      }
      return this.isValid; // 返回最终验证状态

    },


    subvalidate (val) {
      this.isValid = true; // 设置为有效
      if (val.subject_id == 7 || val.subject_id == 12 || val.subject_id == 11) {
        if (val.score < 37) {
          this.$message.error(`${this.subjectListData.find(item => item.subject_id == val.subject_id).subject_name}目标分数最底：37分`);
          this.isValid = false;  // 设置为无效
        } else if (val.score > 150) {
          this.$message.error(`${this.subjectListData.find(item => item.subject_id == val.subject_id).subject_name}目标分数最高：150分`);
          this.isValid = false;  // 设置为无效
        }
      } else {

        if (val.score < 25) {
          this.$message.error(`${this.subjectListData.find(item => item.subject_id == val.subject_id).subject_name}目标分数最底：25分`);
          this.isValid = false;  // 设置为无效
        } else if (val.score > 100) {
          this.$message.error(`${this.subjectListData.find(item => item.subject_id == val.subject_id).subject_name}目标分数最高：100分`);
          this.isValid = false;  // 设置为无效
        }
      }

      return this.isValid; // 返回最终验证状态
    },
    async save () {


      this.subjectList.map(item2 => {
        this.subjectListData.map(item => {
          if ((item2.subject_id == item.subject_id)) {
            item2.score = item.score
          }
        })
      })
      let validationResults = [];
      validationResults = this.subjectList.map(item => {
        setTimeout(() => {
          return this.subvalidate(item);
        }, 100)
      });
 
      const isTrue = validationResults.some(result => result == false)
     
      if (isTrue==false) {
     
        return; // 如果不可以继续，直接返回，不发送请求
      }
   
      
      let data = { "subject_score": this.subjectList }

      this.$http({
        url: '/api/v1/user/set_user_hope_score',
        method: 'post',
        data: data,
      }).then(res => {

        let totalScore = 0; // 定义变量用于累加分数
        let subjectListData = [];
        subjectListData = this.subjectList.filter(item => item.subject_id !== 13).map(item => {
          totalScore += item.score;

          if ((item.subject_id === 7 || item.subject_id === 12 || item.subject_id === 11) && item.score == 0) {
            item.score = 130; // 设置为130
          } else if (item.score == 0) {
            item.score = 90; // 其他设为90
          }

          return {
            subject_id: item.subject_id,
            score: item.score,
          }
        });
        this.$EventBus.emit('totalScoreEdit', totalScore)
        window.localStorage.setItem('subjectListData', JSON.stringify(subjectListData))
        window.localStorage.setItem('totalScore', totalScore)
        this.$message.success('修改成功')
      })

    },

  }
}
</script>

<style lang="scss" scoped>
.info_warp {
  display: flex;
  justify-content: center;
  .wrap {
    width: 572rem;
    .head_img {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 32rem;
      .title {
        color: var(--light-333333, var(--neutral-color-333333, #333));
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 42px; /* 150% */
      }
      .text {
        color: var(--neutral-color-666666, #666);
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 150% */
      }
    }
    .el-form {
      margin-top: 50rem;
      ::v-deep .el-form-item__label {
        font-size: 24rem;
        font-weight: 500;
        color: #333333;
        // line-height: 60rem;
      }
      ::v-deep .el-input--medium .el-input__inner {
        // height: 60rem;
        // line-height: 60rem;
        font-size: 24rem;
        font-weight: 500;
        color: #333333;
      }
    }

    .btn {
      width: 372rem;
      height: 60rem;
      background: #2196f3;
      border-radius: 10rem;
      font-size: 24rem;
      font-weight: bold;
      color: #ffffff;
      line-height: 60rem;
      text-align: center;
    }
  }
}
.readonly {
  width: 100%;
  height: 60rem;
  z-index: 1999;
  position: absolute;
  top: 0;
}
</style>